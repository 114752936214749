<template>
  <div class="page-container">
    <div class="container my-8">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.page-container {
  background-color: white;
  text-align: start;
}
</style>

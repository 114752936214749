<template>
  <policy>
    <h1>Terms and Privacy</h1>
    <p>
      Here you get all legal information which involves terms of service,
      privacy policy, cookies policy, and many more.
    </p>
    <ul>
      <li>
        <router-link to="/legal/terms-of-service" class="text-decoration-none">
          Terms of Service
        </router-link>
      </li>
      <li>
        <router-link to="/legal/privacy" class="text-decoration-none">
          Privacy Policy
        </router-link>
      </li>
      <li>
        <router-link to="/legal/cookies" class="text-decoration-none">
          Cookies Policy
        </router-link>
      </li>
    </ul>
  </policy>
  <router-view></router-view>
</template>

<script>
import Policy from "@/layouts/Policy.vue"

export default {
  components: { Policy }
}
</script>
